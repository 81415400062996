import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as _91_91type_93_93xrnVgJ3ceKMeta } from "/opt/buildhome/repo/pages/book/[[type]].vue?macro=true";
import { default as consentl1u4iMom2RMeta } from "/opt/buildhome/repo/pages/consent.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as faq6z6qNnXVtzMeta } from "/opt/buildhome/repo/pages/faq.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as serviceszlfYtypW62Meta } from "/opt/buildhome/repo/pages/services.vue?macro=true";
export default [
  {
    name: aboutcGvMViY0SnMeta?.name ?? "about",
    path: aboutcGvMViY0SnMeta?.path ?? "/about",
    meta: aboutcGvMViY0SnMeta || {},
    alias: aboutcGvMViY0SnMeta?.alias || [],
    redirect: aboutcGvMViY0SnMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91_91type_93_93xrnVgJ3ceKMeta?.name ?? "book-type",
    path: _91_91type_93_93xrnVgJ3ceKMeta?.path ?? "/book/:type?",
    meta: _91_91type_93_93xrnVgJ3ceKMeta || {},
    alias: _91_91type_93_93xrnVgJ3ceKMeta?.alias || [],
    redirect: _91_91type_93_93xrnVgJ3ceKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/book/[[type]].vue").then(m => m.default || m)
  },
  {
    name: consentl1u4iMom2RMeta?.name ?? "consent",
    path: consentl1u4iMom2RMeta?.path ?? "/consent",
    meta: consentl1u4iMom2RMeta || {},
    alias: consentl1u4iMom2RMeta?.alias || [],
    redirect: consentl1u4iMom2RMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/consent.vue").then(m => m.default || m)
  },
  {
    name: contactupLWW5VsuQMeta?.name ?? "contact",
    path: contactupLWW5VsuQMeta?.path ?? "/contact",
    meta: contactupLWW5VsuQMeta || {},
    alias: contactupLWW5VsuQMeta?.alias || [],
    redirect: contactupLWW5VsuQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: faq6z6qNnXVtzMeta?.name ?? "faq",
    path: faq6z6qNnXVtzMeta?.path ?? "/faq",
    meta: faq6z6qNnXVtzMeta || {},
    alias: faq6z6qNnXVtzMeta?.alias || [],
    redirect: faq6z6qNnXVtzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: serviceszlfYtypW62Meta?.name ?? "services",
    path: serviceszlfYtypW62Meta?.path ?? "/services",
    meta: serviceszlfYtypW62Meta || {},
    alias: serviceszlfYtypW62Meta?.alias || [],
    redirect: serviceszlfYtypW62Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/services.vue").then(m => m.default || m)
  }
]