<template>
  <header>
    <NavBar/>
  </header>
  <main>
    <NuxtPage/>
  </main>
  <footer>
    <Footer/>
  </footer>
</template>

<script lang="ts" setup>
import {theme} from "#tailwind-config";

const img = useImage();
const appConfig = useAppConfig()
const siteConfig = useSiteConfig();
const route = useRoute();

useHead({
  titleTemplate: (title) => title ? `${title} | ${appConfig.title}` : appConfig.title,
  link: [
    {rel: 'sitemap', type: 'application/xml', href: '/sitemap.xml'},
    {rel: 'canonical', href: `${siteConfig.url}${route.fullPath}`},
    {rel: 'apple-touch-icon', sizes: '180x180', href: img('logos/rosecroft-logo-white.png', {width: 180})},
    {rel: 'icon', type: "image/png", sizes: '32x32', href: img('logos/rosecroft-logo-color-bg.png', {width: 32})},
    {rel: 'icon', type: "image/png", sizes: '64x64', href: img('logos/rosecroft-logo-color-bg.png', {width: 64})},
  ],
  meta: [
    {name: "theme-color", content: theme.colors.rosecroft.gray},
  ],
  htmlAttrs: {
    lang: "en-GB"
  }
})
</script>
