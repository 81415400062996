<script setup lang="ts">
import {Bars3Icon} from '@heroicons/vue/24/solid'

const menuExpanded = ref<boolean>(false);

</script>

<template>
  <nav class="bg-rosecroft-gray">
    <ul id="page-links" :class="menuExpanded ? 'max-h-screen' : 'max-h-0'">
      <li class="brand-logo print:visible max-w-fit">
        <NuxtLink @click="menuExpanded = false" to="/">
          <NuxtPicture class="items-center justify-center"
                       :img-attrs="{class: 'h-20 w-auto ml-auto mr-auto'}"
                       src="logos/rosecroft-logo-color.png"
                       alt="Rosecroft Rehab"
          />
        </NuxtLink>
      </li>
      <li>
        <NuxtLink to="/" @click="menuExpanded = false">Home</NuxtLink>
      </li>
      <li>
        <NuxtLink @click="menuExpanded = false" to="/about/">About Us</NuxtLink>
      </li>
      <li>
        <NuxtLink @click="menuExpanded = false" to="/services/">Services & Prices</NuxtLink>
      </li>
      <li>
        <!--        <NuxtLink @click="menuExpanded = false" to="/testimonials/">Testimonials</NuxtLink>-->
        <NuxtLink to="/book/" @click="menuExpanded = false">Book Online</NuxtLink>
      </li>
      <li>
        <NuxtLink @click="menuExpanded = false" to="/faq/">FAQ</NuxtLink>
      </li>
      <li>
        <NuxtLink to="/contact/" @click="menuExpanded = false">Contact Us</NuxtLink>
      </li>
    </ul>

    <ul class="flex md:hidden print:block print:md:block print:text-center justify-between">
      <li class="shrink">
        <NuxtLink class="my-2" to="/">
          <NuxtPicture class="items-center justify-center"
                       :img-attrs="{class: 'h-20 w-auto ml-auto mr-auto'}"
                       src="logos/rosecroft-logo-color.png"
                       alt="Rosecroft Rehab"
          />
        </NuxtLink>
      </li>
      <li class="shrink items-center flex print:hidden">
        <button class="px-2 inline-grid grid-cols-2 items-center border border-rosecroft-light rounded text-white"
                @click="menuExpanded = !menuExpanded">
          <bars3-icon class="h-10 w-10 inline text-rosecroft-light"/>
          MENU
        </button>
      </li>
    </ul>
  </nav>


</template>

<style lang="postcss" scoped>
nav {
  ul {
    @apply container mx-auto items-stretch md:max-h-screen overflow-hidden transition-all duration-500 ease-in-out;
  }

  #page-links {
    @apply flex flex-col md:flex-row print:hidden;

    li {
      @apply inline-grid flex-auto items-center text-center font-serif italic text-2xl text-white;
      border-top: 1px solid theme('colors.rosecroft.light');
      border-bottom: 1px solid theme('colors.rosecroft.light');
      margin: 0.5rem;

      &.brand-logo {
        border-top: none;
        border-bottom: none;
        @apply hidden md:inline-grid;
      }

      @media screen(md) {
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
