export default defineAppConfig({
    title: 'Rosecroft Canine Rehabilitation Centre',
    colorMode: {
        preference: 'light',
        fallback: 'light',
    },
    ui: {
        primary: 'rosecroft',
        secondary: 'empress',
        icons: {
            dynamic: true,
        },
    }
})
