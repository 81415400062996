import {jarallax} from "jarallax";
import 'jarallax/dist/jarallax.min.css';

export default defineNuxtPlugin({
  name: "Jarallax",

  hooks: {
    'page:finish'() {
      jarallax(document.getElementsByClassName("jarallax"), {});
    }
  }
})
