<script setup>
const error = useError();
</script>

<template>
  <div class="flex flex-col min-h-[calc(100vh-5rem)]">
    <header class="flex-none">
      <NavBar/>
    </header>

    <div class="grow flex flex-col justify-center text-center">
      <div class="p-20">
        <template v-if="error.statusCode === 404">
          <UIcon class="text-[5rem] lg:text-[8rem]" dynamic name="i-ph-paw-print"/>
          <h1>404</h1>
          <p>Sorry, that page doesn't exist.</p>
        </template>

        <template v-else>
          <h1>Dang
            <UIcon class="rotate-12" dynamic name="i-ph-paw-print"/>
          </h1>
          <p>
            <strong>{{ error.message }}</strong>
          </p>
          <p>It looks like something broke.</p>
          <p>Sorry about that.</p>
        </template>
      </div>
    </div>
  </div>

  <footer>
    <Footer/>
  </footer>
</template>

<style lang="postcss" scoped>

</style>
