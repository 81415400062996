<script setup lang="ts">
const year = new Date().getFullYear();
</script>

<template>
  <div class="container mx-auto text-center pt-10">
    <div class="text-center grid md:grid-cols-3 gap-5 w-full">
      <div class="md:order-last">
        <div>Follow us</div>
        <div class="flex justify-center gap-10 text-4xl mt-2 md:mt-5">
          <a href="https://www.instagram.com/rosecroft_rehab/" rel="me">
            <UIcon name="uim:instagram"/>
          </a>
          <a href="https://www.facebook.com/people/Rosecroft-Canine-Rehabilitation-Centre/100089272941354/" rel="me">
            <UIcon name="uim:facebook-f"/>
          </a>
        </div>
      </div>
      <div>
        <div>
          <NuxtLink href="tel:+441785561398">
            <UIcon name="uil:phone"/>
            01785 561398
          </NuxtLink>
        </div>
        <div>
          <NuxtLink href="mailto:reception@rosecroftrehab.co.uk">
            <UIcon name="uil:envelope"/>
            reception@rosecroftrehab.co.uk
          </NuxtLink>
        </div>
        <div>165 Silkmore Lane, Stafford, ST17 4JB</div>
      </div>
      <div class="print:hidden md:order-first">
        <div>
          Opening Times:
        </div>
        <table class="inline-block">
          <tr>
            <td>Mon:</td>
            <td> 10am - 6pm</td>
          </tr>
          <tr>
            <td>Tues:</td>
            <td> 8am - 3pm</td>
          </tr>
          <tr>
            <td>Wed:</td>
            <td> CLOSED</td>
          </tr>
          <tr>
            <td>Thurs:</td>
            <td> 2pm - 7:30pm</td>
          </tr>
          <tr>
            <td>Fri:</td>
            <td> 8am - 1pm</td>
          </tr>
          <tr>
            <td>Sat:</td>
            <td> 9am - 4pm</td>
          </tr>
          <tr>
            <td>Sun:</td>
            <td> VARIOUS</td>
          </tr>
        </table>
      </div>
    </div>
    <div>&copy; {{ year }} Rosecroft Canine Rehabilitation Centre</div>
    <div>Website by
      <NuxtLink rel="external" to="https://www.codedinternet.com">Coded Internet</NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="postcss">
table {
  td {
    @apply px-1;

    &:first-child {
      @apply text-left;
    }

    &:last-child {
      @apply text-right;
    }
  }
}
</style>
